/* eslint-disable @typescript-eslint/naming-convention */
import { Payment, Provider } from './../../models/payment.model';
import { UserStore } from './../../store/user.store';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private readonly API = `${environment.api}`;
  private readonly CLINIC_ID = `${environment.clinic.id}`;

  constructor(private http: HttpClient, private userStore: UserStore) { }

  list(): Observable<any> {
    let params = {
      public_id: this.userStore.id
    }

    return this.http.get<any>(`${this.API}/payment`, { params })
      .pipe(
        take(1)
      );
  }

  listByAppointment(appointmentId: any) {
    return this.http
      .get<Payment[]>(
        `${this.API}/payments?clinicId=${this.CLINIC_ID}&patientId=${this.userStore.id}&appointmentId=${appointmentId}`
      )
      .pipe(
        take(1),
      );
  }

  get(paymentId) {
    return this.http.get<Payment>(`${this.API}/payments/${paymentId}`)
      .pipe(
        take(1)
      );
  }

  getSession(providerName: any) {
    return this.http.get<Payment>(`${this.API}/payments/providers/session?clinicId=${this.CLINIC_ID}&providerName=${providerName}`)
      .pipe(
        take(1)
      );
  }

  getProviders() {
    return this.http.get<Provider[]>(`${this.API}/payments/providers?clinicId=${this.CLINIC_ID}`)
      .pipe(
        take(1)
      );
  }

  registerPaymentTransaction(paymentId: any, data: any) {
    return this.http.post<any>(`${this.API}/payments/${paymentId}/transactions`, data)
      .pipe(
        take(1)
      );
  }

  getProfessionalPrices(data): Observable<any> {
    return this.http.get<any>(`${this.API}/payment/get-price/${data.professional_id}/${data.specialty_id}/${data.in_person}`)
      .pipe(
        take(1)
      );
  }
}
