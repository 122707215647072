import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ExceptionService {
  constructor(private toastr: ToastController) {}

  getExceptionMessage(exceptionCode: string): string {
    let message: string;
    switch (exceptionCode) {
      case 'user_not_found':
        message = 'Usuário não encontrado';
        break;

      case 'invalid_credentials':
        message = 'Credenciais de usuário inválidas';
        break;

      case 'invalid_email':
        message = 'E-mail inválido';
        break;

      case 'invalid_password':
        message = 'Senha inválida';
        break;

      case 'invalid_refresh_token':
        message = 'Token de atualização inválido para novo acesso. Faça login novamente!';
        break;

      case 'invalid_user_email_cpf':
        message = 'E-mail inválido para o CPF informado!';
        break;

      case 'email_in_use':
        message = 'E-mail já está sendo usado por outro usuário';
        break;

      case 'cpf_in_use':
        message = 'CPF já está sendo usado por outro usuário';
        break;

      case 'cannot_confirm_appointment_with_payment_pending':
        message = 'Não é possível confirmar um agendamento com pagamento pendente. Realize o pagamento.';
        break;

      case 'invalid_appointment_price':
        message = 'Não é possível finalizar o agendamento. A clínica não definiu um preço para este tipo de consulta.';
        break;

      case 'appointment_already_confirmed_on_date':
        message = 'Agendamento não permitido. Existe uma agendamento para este horário neste local. Escolha outro horário.';
        break;

      case 'schedules_not_found':
        message = 'Não existe disponibilidade de agenda para o profissional indicado. Por favor selecione outro!!';
        break;

      case 'coverages_not_found':
        message = 'Não existe disponibilidade de agenda para o convênio médico selecionado. Tente outro convênio.';
        break;

      case 'via_cep_not_found':
        message = 'CEP não encontrado. Informe um CEP válido ou verifique o digitado.';
        break;

      case 'Account already exists.':
        message = 'O CPF informado já está atualmente em uso.';
        break;

      default:
        message = 'Ocorreu um erro interno no aplicativo. Tente novamente mais tarde esta ação';
        break;
    }
    return message;
  }
}
