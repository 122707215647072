import { AuthenticationStore } from './../store/authentication.store';
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
  HttpClient,
  HttpResponse,
} from '@angular/common/http';
import { Observable, from, throwError, BehaviorSubject, of } from 'rxjs';
import { Storage } from '@ionic/storage';
// import { _throw } from 'rxjs/observable/throw';
import {
  catchError,
  mergeMap,
  switchMap,
  filter,
  take,
  map,
  tap,
  finalize,
} from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from '../services/authentication.service';
import { StorageService } from '../services/storage.service';
import { AuthenticationState } from '../store/authentication.store';
import { environment } from 'src/environments/environment';
import { MessageService } from '../services/message.service';
import { ExceptionService } from '../services/exception.service';
import { GlobalService } from '../services/global.service';

@Injectable({
  providedIn: 'root',
})
export class HttpCustomInterceptor implements HttpInterceptor {
  constructor(
    private authenticationStore: AuthenticationStore,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private exceptionService: ExceptionService,
    private globalService: GlobalService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isApiUrl = request.url.startsWith(environment.api) || request.url.startsWith(environment.sso);
    if (isApiUrl) {
      if (this.authenticationStore?.accessToken) {
        request = request.clone(
          {
            headers: request.headers.set('Authorization', 'Bearer ' + this.authenticationStore?.accessToken)
          });
      }

      request = request.clone(
        {
          headers: request.headers.set('accept', 'application/json')
        });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        err.error.reason = err.error.detail;
        if (err.status == 401 || err.status == 402 || err.status == 403) {
          this.globalService.clearStorageData();
          this.authenticationService.logout();
        }
        return throwError(err);
      })
    );
  }
}

