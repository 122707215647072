/* eslint-disable @typescript-eslint/member-ordering */
import { UserStore } from './../store/user.store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationState, AuthenticationStore } from '../store/authentication.store';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private avatar  = new BehaviorSubject<string>(null);
  avatar$         = this.avatar.asObservable();

  constructor(private router: Router, private authenticationStore: AuthenticationStore) {
    this.avatar.next(null);
  }

  setAvatar(avatar) {
    this.avatar.next(avatar);
  }

  rootRedirect() {
    if (this.authenticationStore.isAuthenticated()) {
      this.router.navigate(['/tabs/home']);
    } else {
      this.router.navigateByUrl('/auth/login', { replaceUrl: true });
    }
  }

  logout() {
    this.rootRedirect();
  }

}
