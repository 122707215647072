/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Payment, RegisterPaymentTransaction } from './../../models/payment.model';
import { Provider } from './../../models/payment.model';
import { ClinicStore } from './../../store/clinic.store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { PagSeguroData, PagSeguroOptions, PagSeguroDefaultOptions } from '../../models/pagseguro.model';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { map, take } from 'rxjs/operators';
import { PaymentService } from '../../services/backend/payment.service';
import { Patient, PatientDetails } from '../../models/patient.model';
import * as moment from 'moment';


declare let PagSeguroDirectPayment: any;

@Injectable({
  providedIn: 'root',
})
export class PagSeguroService {
  public  amount$:        Observable<number>;
  public  patient$:       Observable<PatientDetails>;
  public  payment$:       Observable<Payment>;
  public  installments:   any;
  public  creditCardHash: any;

  private scriptLoaded:   boolean;
  private options:        PagSeguroOptions;
  private paymentForm:    FormGroup;
  private amountSource:   BehaviorSubject<number>;
  private patientSource:  BehaviorSubject<PatientDetails>;
  private paymentSource:  BehaviorSubject<Payment>;

  private maxInstallmentNoInterest: number;


  constructor(
    private paymentService: PaymentService,
    private http:           HttpClient,
    public  platform:       Platform,
    private clinicStore:    ClinicStore
  ) {
    this.options       = PagSeguroDefaultOptions;

    this.amountSource  = new BehaviorSubject<number>(0);
    this.amount$       = this.amountSource.asObservable();
    this.patientSource = new BehaviorSubject<PatientDetails>(null);
    this.patient$      = this.patientSource.asObservable();
    this.paymentSource = new BehaviorSubject<Payment>(null);
    this.payment$      = this.paymentSource.asObservable();

  }

  public setOptions(options: PagSeguroOptions) {
    this.options = Object.assign(PagSeguroDefaultOptions, options);
  }

  public getOptions(): PagSeguroOptions {
    return this.options;
  }

  public setForm(paymentForm: FormGroup) {
    this.paymentForm = paymentForm;
  }

  public setAmount(amount: number) {
    this.amountSource.next(amount);
  }

  public setPatient(patient: PatientDetails) {
    this.patientSource.next(patient);
  }

  public setPayment(payment: Payment) {
    this.paymentSource.next(payment);
  }

  public getSelectedPaymentMethod(): string {
    return this.paymentForm.value.paymentMethod;
  }

  async getPayment(): Promise<Payment> {
    return this.payment$.pipe(take(1),
              map((res) => res)
            ).toPromise();
  }

  async getPatient(): Promise<PatientDetails> {
    return this.patient$.pipe(take(1),
              map((res) => res)
            ).toPromise();
  }

  async getAmount(): Promise<number> {
    return this.amount$.pipe(take(1),
              map((res) => res)
            ).toPromise();
  }

  async getProviders(): Promise<Provider> {
    return this.paymentService
      .getProviders()
          .pipe(
            take(1),
            map((res) => res.filter((x) => x.name.toLowerCase() === 'pagseguro')[0])
          ).toPromise();
  }

  /**
   * Carrega o <script> do PagSeguro no HEAD do documento
   */
  public loadScript(): Promise<any> {
    const promise = new Promise<void>((resolve) => {
      if (this.options.loadScript && !this.scriptLoaded) {
        const script: HTMLScriptElement = document.createElement('script');
        script.addEventListener('load', r => resolve());
        script.src = this.options.scriptURL;
        document.head.appendChild(script);

        this.scriptLoaded = true;
      } else {
        console.log('Script is already loaded. Skipping...');
        resolve();
      }
    });
    return promise;
  }

  /**
   * Inicia a sessao com o PagSeguro, invocando uma Firebase Function
   */
  //public startSession(): Observable<any> {
  public startSession(): Promise<any> {
    return this.paymentService.getSession('pagseguro').toPromise();
  }

  /**
   * Recupera as opções de pagamento.
   * Esta funcção deve ser chamada após já termos iniciado a sessão, pelo startSession()
   */
  public getPaymentMethods(amount: number): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      // recupera as opçoes de pagamento através da API Javscript do PagSeguro
      PagSeguroDirectPayment.getPaymentMethods({
        amount,
        success: function(response) {
          resolve(response);
        },
        error: function(response) {
          reject(response);
        }
      });
    });
    return promise;
  }

  public getInstallments(amount: number, brand: string, maxInstallmentNoInterest: number): Promise<any> {
    this.maxInstallmentNoInterest = maxInstallmentNoInterest;
    const that = this;
    const promise = new Promise((resolve, reject) => {
      PagSeguroDirectPayment.getInstallments({
        amount,
        brand,
        maxInstallmentNoInterest,
        success: function(response) {
          that.installments = response.installments[brand];
          resolve(response);
        },
        error: function(response) {
          reject(response);
        },
      });
    });

    return promise;

  }

  /**
   * Recupera a bandeira do cartão através dos 6 primeiros numeros do cartão (PIN)
   */
  public getCardBrand(pin: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      PagSeguroDirectPayment.getBrand({
        cardBin: pin,
        success: function(response) {
          resolve(response);
        },
        error: function(response) {
          reject(response);
        }
      });
    });
    return promise;
  }

  /**
   * Recupera o valor da parcela individual para a quantidade de parcelas selecionadas
   */
  private getAmountForInstallmentQuantity(quantity) {
    for (let i = 0; i < this.installments.length; i++) {
      if (this.installments[i].quantity === quantity) {
        return this.installments[i].installmentAmount.toFixed(2);
      }
    }
    return '0.00';
  }

  /**
   * Monta o objeto necessário para a API do PagSeguro
   */
  async buildPagSeguroData(): Promise<PagSeguroData> {

    const provider = await this.getProviders();
    const patient  = await this.getPatient  ();
    const amount   = await this.getAmount   ();
    const payment  = await this.getPayment  ();

    const data: PagSeguroData =
    {
      paymentMode:                   'default',
      paymentMethod:                 'creditCard',
      receiverEmail:                 provider.email,
      currency:                      'BRL',
      extraAmount:                   '0.00',
      itemId1:                       '0001',
      itemDescription1:              provider.description || 'CONSULTA MÉDICA',
      itemAmount1:                   amount.toFixed(2).toString().replace(',', '.'),
      itemQuantity1:                 '1',
      notificationURL:               'https://sualoja.com.br/notifica.html',
      reference:                     payment.appointment.id,
      senderName:                    `${patient.firstName} ${patient.lastName}`,
      senderCPF:                     patient.cpf,
      senderAreaCode:                patient.cellPhoneNumber.substring(0, 2),
      senderPhone:                   patient.cellPhoneNumber.substring(2),
      senderEmail:                   patient.email,
      senderHash:                    '',
      shippingAddressRequired:       'false',
      shippingAddressStreet:         '',
      shippingAddressNumber:         '',
      shippingAddressComplement:     '',
      shippingAddressDistrict:       '',
      shippingAddressPostalCode:     '',
      shippingAddressCity:           '',
      shippingAddressState:          '',
      shippingAddressCountry:        '',
      shippingType:                  '',
      shippingCost:                  '',
      creditCardToken:               '',
      installmentQuantity:           Number(this.paymentForm.value.installments).toString(),
      installmentValue:              this.getAmountForInstallmentQuantity(this.paymentForm.value.installments),
      noInterestInstallmentQuantity: this.maxInstallmentNoInterest.toString().replace(',', '.'),
      creditCardHolderName:          this.paymentForm.value.name,
      creditCardHolderCPF:           patient.cpf,
      creditCardHolderBirthDate:     (moment(patient.birthDate)).format('DD/MM/YYYY'),
      creditCardHolderAreaCode:      patient.cellPhoneNumber.substring(0, 2),
      creditCardHolderPhone:         patient.cellPhoneNumber.substring(2),
      billingAddressStreet:          patient.addressStreet,
      billingAddressNumber:          patient.addressNumber,
      billingAddressComplement:      patient.addressComplement,
      billingAddressDistrict:        patient.addressDistrict,
      billingAddressPostalCode:      patient.addressZipCode,
      billingAddressCity:            patient.addressCity,
      billingAddressState:           patient.addressUf,
      billingAddressCountry:         'BRA',
    };

    return data;
  }

  /**
   * Função que realiza o pagamento com o PagSeguro.
   *
   * @param data
   */
  public async checkout(): Promise<any> {
    const transation: PagSeguroData = await this.buildPagSeguroData();
    const payment: Payment          = await this.getPayment();

    transation.senderHash = PagSeguroDirectPayment.getSenderHash();
    return this.createCardToken().then(result => {
      transation.creditCardToken = result.card.token;
      return this._checkout(payment, transation);
    });
  }

  /**
   * Essa API deverá chamar a função de /transactions do PagSeguro para concluir a transação
   *
   * @param data
   */
  private _checkout(payment: Payment, data: PagSeguroData): Promise<any> {

    const request: RegisterPaymentTransaction = {
      clinicId:    payment.clinicId,
      patientId:   payment.patientId,
      paymentId:   payment.id,
      provider:    'pagseguro',
      type:        'credit',
      value:       payment.value,
      transaction: {
        pagseguro: data
      }
    };

    const promise = new Promise((resolve, reject) => {
      this.paymentService.registerPaymentTransaction(payment.id, request)
      .pipe(take(1))
      .subscribe(
        (transaction) => {
          resolve(transaction);
        },
        (error) =>{
          reject(error);
        }
      );
    });
    return promise;
  }

  /**
   * Cria um Token para o cartão de crédito informado
   *
   * @param data
   */
  public createCardToken(): Promise<any> {

    const promise = new Promise((resolve, reject) => {
      PagSeguroDirectPayment.createCardToken({
        cardNumber:      this.paymentForm.value.cardNumber,
        cvv:             this.paymentForm.value.cvv,
        expirationMonth: this.paymentForm.value.month,
        expirationYear:  this.paymentForm.value.year,
        success: function(response) {
          resolve(response);
        },
        error: function(response) {
          reject(response);
          console.log(response);
        }
      });
    });
    return promise;
  }

}

