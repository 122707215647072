import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PagSeguroService } from './components/pagseguro/pagseguro.service';
import { AuthenticationService } from './services/authentication.service';
import { GlobalService } from './services/global.service';
import { MessageService } from './services/message.service';
import { AuthenticationStore } from './store/authentication.store';
import { UserStore } from './store/user.store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private userStore: UserStore,
    private authenticationStore: AuthenticationStore,
    private globalService: GlobalService,
    private authService: AuthenticationService,
    private messageService: MessageService,
    private platform: Platform,
    private pagSeguroService: PagSeguroService
  ) {
    this.initialize();
  }

  initialize() {
    this.authenticationStore.load().then((state) => {
      if (this.authenticationStore.isAuthenticated()) {
        this.globalService.loadStorageData().then(() => {
          this.authenticationStore.startRefreshTokenTimer(state.expires);
        })
        .catch((error) => this.messageService.warning(error.error.message));
      }
      this.authService.rootRedirect();
    })
    .catch((error) => {
      this.messageService.warning(error.error.message);
    });

    this.platform.ready().then(() => {
      // Inicialize a biblioteca do PagSeguro bo seu app.component
      this.pagSeguroService.setOptions({
        scriptURL: 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js'
      });
    });

    // pre-loading script
    setTimeout(() => {
      this.pagSeguroService.loadScript();
    }, 1000);
  }
}
