import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthenticationStore } from '../store/authentication.store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(
    private authenticationStore: AuthenticationStore,
    private router: Router
  ) { }

  canLoad(): boolean {
    if (!this.authenticationStore.isAuthenticated()) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    return true;
  }
}
