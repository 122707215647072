/* eslint-disable @typescript-eslint/naming-convention */
 export interface PagSeguroData {
  paymentMode: string;
  paymentMethod: string;
  receiverEmail: string;
  currency: string;
  extraAmount: string;
  itemId1: string;
  itemDescription1: string;
  itemAmount1: string;
  itemQuantity1: string;
  notificationURL: string;
  reference: string;
  senderName: string;
  senderCPF: string;
  senderAreaCode: string;
  senderPhone: string;
  senderEmail: string;
  senderHash: string;
  shippingAddressRequired: string;
  shippingAddressStreet: string;
  shippingAddressNumber: string;
  shippingAddressComplement: string;
  shippingAddressDistrict: string;
  shippingAddressPostalCode: string;
  shippingAddressCity: string;
  shippingAddressState: string;
  shippingAddressCountry: string;
  shippingType: string;
  shippingCost: string;
  creditCardToken: string;
  installmentQuantity: string;
  installmentValue: string;
  noInterestInstallmentQuantity: string;
  creditCardHolderName: string;
  creditCardHolderCPF: string;
  creditCardHolderBirthDate: string;
  creditCardHolderAreaCode: string;
  creditCardHolderPhone: string;
  billingAddressStreet: string;
  billingAddressNumber: string;
  billingAddressComplement: string;
  billingAddressDistrict: string;
  billingAddressPostalCode: string;
  billingAddressCity: string;
  billingAddressState: string;
  billingAddressCountry: string;
}

export interface PagSeguroOptions  {
  scriptURL?: string;             // A URL do .js do PagSeguro (produção ou sandbox)
  filesURL?: string;               // URL base para imagens do PagSeguro
  loadScript?: boolean;            // caso queira que este Componente carregue o .js automaticamente
}

export const PagSeguroDefaultOptions: PagSeguroOptions = {
  scriptURL: 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js',
  filesURL: 'https://stc.pagseguro.uol.com.br',
  loadScript: true
};
