import { UserStore } from './../store/user.store';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { ClinicStore } from '../store/clinic.store';
import { AuthenticationStore } from '../store/authentication.store';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    private clinicStore: ClinicStore,
    private userStore: UserStore,
    private authenticationStore: AuthenticationStore
  ) { }

  loadServerData(): Promise<any> {
    return Promise.all([
      this.clinicStore.set(),
      this.userStore.set()
    ]);
  }

  loadStorageData(): Promise<any> {
    return Promise.all([
      this.clinicStore.load(),
      this.userStore.load()
    ]);
  }

  clearStorageData(): void {
    this.authenticationStore.clear();
    this.clinicStore.clear();
    this.userStore.clear();
  }
}
