/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Store } from './store';
import { IdentityService } from '../services/backend/identity.service';
import { StorageService } from '../services/storage.service';
import { Clinic } from '../models/clinic.model';
import { ClinicService } from '../services/backend/clinic.service';

export class ClinicState implements Clinic {
  clinicId: string;
  companyName: string;
  tradingName: string;
  cnpj: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  inactivationReason: string;
  aggregateMode: boolean;
}

@Injectable()
export class ClinicStore extends Store<ClinicState> {

  constructor(
    private clinicService: ClinicService,
    storageService: StorageService
  ) {
    super(new ClinicState(), 't-clinic', storageService);
  }

  get clinicId(): string {
    return this.state.clinicId;
  }

  get companyName(): string {
    return this.state.companyName;
  }

  get tradingName(): string {
    return this.state.tradingName;
  }

  get email(): string {
    return this.state.email;
  }

  get aggregateMode(): boolean {
    return this.state.aggregateMode;
  }

  async update(data) {
    const state = this.state;

    if (data.id !== undefined) { state.clinicId = data.id; }
    if (data.email !== undefined) { state.email = data.email; }
    if (data.name !== undefined) { state.companyName = data.name; }

    this.setState(this.state);
    return state;
  }

  set(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.clinicService.get().subscribe(data => {
        this.update(data.content[0]);
        resolve(data);
      }, (error) => {
        console.error(error);
        reject(error);
      });
    });
  }
}
