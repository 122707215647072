/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Store } from './store';
import { StorageService } from '../services/storage.service';
import { Authentication } from '../models/authentication.model';
import { IdentityService } from '../services/backend/identity.service';
import { from } from 'rxjs';

export class AuthenticationState implements Authentication {
  accessToken: string;
  refreshToken: string;
  role: string;
  expires: number;
  expires_in: number;
  isAuthenticated: boolean;
}

@Injectable()
export class AuthenticationStore extends Store<AuthenticationState> {

  private refreshTokenTimeout;

  constructor(
    private identityService: IdentityService,
    storageService: StorageService
  ) {
    super(new AuthenticationState(), 't-authentication', storageService);
  }

  static isAuthenticated(isAuthenticated) {
    return isAuthenticated;
  }

  get accessToken(): string {
    return this.state.accessToken;
  }

  get refreshToken(): string {
    return this.state.refreshToken;
  }

  get role(): string {
    return this.state.role;
  }

  get expires(): number {
    return this.state.expires;
  }

  async update(data) {
    const state = this.state;

    if (data.access_token !== undefined) {
      state.accessToken = data.access_token;
      this.state.isAuthenticated = true;
    }
    if (data.refresh_token !== undefined) { state.refreshToken = data.refresh_token; }
    if (data.expires_in !== undefined) { state.expires = data.expires_in; }

    this.setState(this.state);
    return state;
  }

  isAuthenticated() {
    this.identityService.validToken().subscribe({
      next: (res) => {
        this.state.isAuthenticated = true
      },
      error: () => {
        this.state.isAuthenticated = false
      }
    })
    return AuthenticationStore.isAuthenticated(this.state.isAuthenticated);
  }

  signIn(credentials): Promise<any> {
    return new Promise((resolve, reject) => {
      this.identityService.signIn(credentials).subscribe(data => {
        this.update(data);
        this.startRefreshTokenTimer((data as AuthenticationState).expires_in);
        resolve(data);
      }, (error) => {
        console.error(error);
        reject(error);
      });
    });
  }

  refresh(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.identityService.refresh({ refreshToken: this.refreshToken }).subscribe(data => {
        this.update(data);
        resolve(data);
      }, (error) => {
        console.error(error);
        reject(error);
      });
    });
  }

  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  startRefreshTokenTimer(tokenExpires) {
    const expires = new Date(tokenExpires * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => from(this.refresh()).subscribe(), timeout);
  }

  clear() {
    super.clear();
    this.stopRefreshTokenTimer();
  }

}
