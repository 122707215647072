import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StorageService } from '../storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly API = `${environment.api}`;

  constructor(private http: HttpClient) {}

  get(): Observable<any> {
    return this.http.get(`${this.API}/units/all`)
      .pipe(
        take(1)
      );
  }
}
