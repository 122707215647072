/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { StorageService } from '../storage.service';
import {
  Authentication,
  ChangeAvatar,
  ChangePassword,
  ForgotPassword,
  RefreshToken,
  SignIn,
  SignUp
} from '../../models/authentication.model';
import { UserStore } from 'src/app/store/user.store';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  user$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private readonly API = `${environment.api}`;
  private readonly SSO = `${environment.sso}`;
  private readonly CLINIC_ID = `${environment.clinic.id}`;

  constructor(private http: HttpClient) { }

  me(): Observable<any> {
    return this.http.get(`${this.API}/account/get`)
      .pipe(
        take(1)
      );
  }

  remove() {
    return this.http.delete(`${this.API}/account/delete`)
      .pipe(
        take(1)
      );
  }

  signIn(data: SignIn) {
    let params = new HttpParams()
      .set('client_id', data.client_id)
      .set('client_secret', data.client_secret)
      .set('username', data.username)
      .set('password', data.password)
      .set('grant_type', data.grant_type);

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(`${this.SSO}/token`, params.toString(), { headers })
      .pipe(
        take(1)
      );
  }

  validToken() {
    return this.http.get(`${this.SSO}/userinfo`).pipe(
      take(1)
    );
  }

  signUp(data: SignUp) {
    return this.http.post(`${this.API}/account/signup`, data)
      .pipe(
        take(1)
      );
  }

  refresh(data) {
    let params = new HttpParams()
      .set('client_id', 'front-dev')
      .set('client_secret', environment.client_secret)
      .set('refresh_token', data.refreshToken)
      .set('grant_type', 'refresh_token');

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(`${this.SSO}/token`, params.toString(), { headers })
      .pipe(
        take(1)
      );
  }

  changeAvatar(data) {
    let formData = new FormData();

    console.log(data);
    formData.append('image', data, 'perfil.jpeg');

    return this.http.put(`${this.API}/account/update_profile_picture`, formData)
      .pipe(
        take(1)
      );
  }

  deleteAvatar() {
    return this.http.put(`${this.API}/account/delete_profile_picture`, {})
      .pipe(
        take(1)
      );
  }

  changePassword() {
    return this.http.patch(`${this.API}/authentication/change-password-authenticated`, {})
      .pipe(
        take(1)
      );
  }

  forgotPassword(data) {
    return this.http.patch(`${this.API}/authentication/change-password-unauthenticated`, {}, { params: data })
      .pipe(
        take(1)
      );
  }
}
