import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserStore } from './store/user.store';
import { AuthenticationStore } from './store/authentication.store';
import { ClinicStore } from './store/clinic.store';
import { HttpCustomInterceptor } from './interceptors/http-custom.interceptor';

import localept from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localept, 'pt-BR');

import { PhonePipe } from './pipes/phone.pipe';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: '__tw',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    })
  ],
  providers: [
    UserStore,
    AuthenticationStore,
    ClinicStore,
    CallNumber,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS , useClass: HttpCustomInterceptor, multi: true },
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    InAppBrowser
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
